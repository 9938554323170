import $ from 'jquery'

$(document).ready(() => {
  $('[data-toggle="hide-nav"]').click((e) => {
    e.preventDefault()

    const link = $(e.currentTarget)
    const target = $(link.data('target'))

    if (target.length) {
      target.toggleClass('slim')
    }
  })
})
