import $ from 'jquery'

function commentReportLinkError($link, error = '') {
  $link.removeClass('disabled')
  $link.addClass('btn-danger')

  if (error) {
    alert(`Une erreur est survenue : ${error}`)
  } else {
    alert('Une erreur est survenue.')
  }

  setTimeout(() => {
    $link.removeClass('btn-danger')
  }, 2500)
}

$(document).ready(() => {
  $('a.comment-report-link').click(function (e) {
    e.preventDefault()

    const $link = $(this)
    const href = $link.attr('href')

    $link.addClass('disabled')

    $.post(href, (response) => {
      if (typeof response.success !== 'undefined') {
        $link.text('Signalé')
        $link.removeClass('btn-danger')
        $link.addClass('btn-success')
      } else {
        commentReportLinkError($link, response.error || '')
      }
    }).fail(() => {
      commentReportLinkError($link)
    })
  })
})
